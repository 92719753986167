import React from "react"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import content_fr from "../../content/hero_fr.yaml"
import content_en from "../../content/hero.yaml"
import content_es from "../../content/hero_es.yaml"

if (typeof window !== `undefined`){
  const currentpath=window.location.pathname; // .replace("/","").replace("/","") replace all "/", normally replace only replaces the first occurence
  if (currentpath.indexOf("fr")>=0){
      var content=content_fr;
  }
  else if(currentpath.indexOf("es")>=0){
    content=content_es;
  }
  else{
    content=content_en;
  }
}
else{
  content=content_en;
}


export default () => (
  <section id="hero">
    <div className="row"> 
      <div className="twelve columns">

        <div className="hero-text">
          <h1 className="responsive-headline">{ content.headline }</h1>
          <p>{ content.body }</p>
        </div>

        <div className="buttons">
          {/* <AnchorLink className="button trial animated shake" to={ content.button1.to }>{ content.button1.label }</AnchorLink> */}
          <a className="button trial animated shake" href={ content.button1.to }>{ content.button1.label }</a>
          <AnchorLink className="button learn-more" to={ content.button2.to }>{ content.button2.label }</AnchorLink>
        </div>

{/*         <div className="hero-image">
          <img src={ content.image } alt="" className="animated fadeInUpBig" />
        </div> */}
      </div>
    </div>
  </section>
)
