import React from "react"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { useSiteMetadata } from "../hooks/use-site-metadata"

import NavMenu from "./navmenu"
import HeaderSocial from "./header-social"
import HeaderLanguages from "./header-languages"

if (typeof window !== `undefined`){
  const currentpath=window.location.pathname;
  if (currentpath.indexOf("fr")>=0){
      var link_ref="/fr/";
  }
  else if(currentpath.indexOf("es")>=0){
    link_ref="/es/";
  }
  else{
    link_ref="/";
  }
}
else{
  link_ref="/";
}


export default () => (
    <header>
      <div className="logo">
         <AnchorLink to={link_ref}>
           <img alt="Website and company logo" src={ useSiteMetadata().logo } />
         </AnchorLink>
      </div>
      <NavMenu />      
      <HeaderLanguages/>
      <HeaderSocial />

    </header>
  )
