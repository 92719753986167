import React, { useState } from "react"
import scrollTo from "gatsby-plugin-smoothscroll"
import Scrollspy from "react-scrollspy"

import { useSiteMetadata } from "../hooks/use-site-metadata"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'

if (typeof window !== `undefined`){
  const currentpath=window.location.pathname
  if (currentpath.indexOf("fr")>=0){
      var language_index=1;
      var lang_url='/fr/';
  }
  else if(currentpath.indexOf("es")>=0){
    language_index=2;
    lang_url='/es/';
  }
  else{
    language_index=0;
    lang_url='/';
  }
  if ((currentpath.indexOf("cgu")>=0)||(currentpath.indexOf("gtu")>=0)){
    var disp_NavMenu=false;
  }
  else{
    disp_NavMenu=true;
  }
}
else{
  language_index=0;
  disp_NavMenu=true;
  lang_url='/';
}

export default function () {
  const [isVisible, setVisibility] = useState(false)

  const { menuItems,languages } = useSiteMetadata()


  let showStyle = null
  if (isVisible){
    showStyle = {transform: 'scaleY(1)'}
  }else{
    showStyle = null
  }

  return(
    <nav id="nav-wrap">
      <button
        id="toggle-btn"
        href="/#"
        title="Menu"
        onClick={() => setVisibility(!isVisible)}
        >
        <FontAwesomeIcon icon={ faBars } />
      </button>

      <Scrollspy
        className="nav mobile"
        style={showStyle}
        items={ menuItems.map(a => a.path) }
        currentClassName="current"
        offset={-100} >

        {menuItems.map((value, index) => {
          if (value.label[language_index]!=='') {
            return (
              <li key={index}>
                <button onClick={() =>
                  {if (disp_NavMenu){                    
                    scrollTo('#' + value.path)
                    setVisibility(0)}
                  else{ 
                    window.location.href=(lang_url+'#' + value.path);
                  }
                }
                }>
                  {value.label[language_index]}
                </button>
              </li>
            )
          }
          else{return(<span></span>)}
        })}
    
    <hr className='navHR'></hr>
      <ul className='navUL'>
      { languages.map((myLanguage) =>{
        return(
        <li><a href={ myLanguage.url }>
          {myLanguage.service}
        </a></li>)}
      )}
    </ul>
    </Scrollspy>
    </nav>
  )
}
