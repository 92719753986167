import React from "react"

import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { useSiteMetadata } from "../hooks/use-site-metadata"




export default () => {
  const { languages } = useSiteMetadata()
  library.add(fab)

  return (
    <ul className="header-languages">
      { languages.map((myLanguage) =>
        <li><a href={ myLanguage.url }>
          <img src={myLanguage.fa} alt={myLanguage.service} />
        </a></li>
      )}
    </ul>
   )
}
