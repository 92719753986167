import React from "react"
import { Helmet } from "react-helmet"

import { useSiteMetadata } from "../hooks/use-site-metadata"

import Header from "./header"
import Footer from "./footer"
import { StructuredData } from '../components/structuredata'

import "../styles/default.css"
import "../styles/layout.css"
import "../styles/media-queries.css"
import "../styles/animate.css"
import "../styles/prettyPhoto.css"

import 'typeface-open-sans';
import 'typeface-montserrat';

import { config } from '@fortawesome/fontawesome-svg-core'
import '@fortawesome/fontawesome-svg-core/styles.css'
config.autoAddCss = false

export default function Layout ({ children }){

  const { title, description, author, favicon } = useSiteMetadata()

  return(
    <>
    <Helmet>
      <title>{ title }</title>
      <meta charset="utf-8"/>
      <meta name="description" content={ description } />
      <meta name="author" content={ author.name } />
      <meta name="keywords" content="Freelance, Translation, traduction, Traducción, french, english, spanish, PROOFREADING, RELECTURE, CORRECTION, REVISIÓN, TRANSCRIPTION, TRANSCRIPCIÓN, Post-edition, JCH, Chastel, Juliette, traducteur, traductrice, translator"/>
    	<meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
      <StructuredData>
{{
  "@context": "http://www.schema.org",
  "@type": "Organization",
  "name": "jchtraduction",
  "logo": "https://jchtraduction.com/images/jchastel_logo_no_background.png",
  "description": "This translation website allows customers to know more about JCHTraduction services, a translation website from Juliette Chastle, professional freelance translator for English French and Spanish.",
  "address": {
     "@type": "PostalAddress",
     "streetAddress": "115 rue de Reuilly",
     "addressLocality": "Paris",
     "postalCode": "75012",
     "addressCountry": "France",
     "availableLanguage": ['English', 'Spanish', 'French'],
  },
   "openingHours": "Mo 09:00-18:00 Tu 09:30-18:00 We 09:30-18:00 Th 09:30-18:00 Fr 09:30-18:00"
}}
</StructuredData>
      

      <meta data-react-helmet="true" name="twitter:card" content="summary_large_image"/>
      <meta data-react-helmet="true" name="twitter:site" contact="@jch-traduction"/>
      <meta name="twitter:creator" content="" data-react-helmet="true"/>
      <meta name="twitter:title" content="JchTraduction - translation services" data-react-helmet="true"/>
      <meta name="twitter:description" content={ description } data-react-helmet="true"/>
      <meta name="twitter:image" content="/images/jchastel_logo_no_background.webp" data-react-helmet="true"/>
      ​
      <meta property="og:site_name" content="" data-react-helmet="true"/>
      <meta property="og:title" content="JchTraduction - translation services" data-react-helmet="true"/>
      <meta property="og:url" content="https://www.jchtraduction.com" data-react-helmet="true"/>
      <meta property="og:description" content={ description } data-react-helmet="true"/>
      <meta property="og:image" content="/images/jchastel_logo_no_background.webp" data-react-helmet="true"/>
      <meta property="og:type" content="Organization" data-react-helmet="true"></meta>

      <link rel="alternate" hreflang="en"
       href="https://www.jchtraduction.com" />
  <link rel="alternate" hreflang="fr"
       href="https://www.jchtraduction.com/fr/" />
  <link rel="alternate" hreflang="es"
       href="https://www.jchtraduction.com/es/" />
 <link rel="alternate" hreflang="x-default"
       href="https://www.jchtraduction.com" />
      
      <link rel="shortcut icon" href={'/' + favicon } type="image/png" />

    </Helmet>

    <Header />

  <main id="top">
    {children}
  </main>

  <Footer />
  </>
  )
}
