import React, {useEffect, useState} from "react"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { useSiteMetadata } from "../hooks/use-site-metadata"
import scrollTo from "gatsby-plugin-smoothscroll"
import { OutboundLink } from "gatsby-plugin-google-gtag"



import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGlobe, faChevronUp, faUsers, faEnvelope } from '@fortawesome/free-solid-svg-icons' /*faMapMarkerAlt and address in usesitemetadata */

if (typeof window !== `undefined`){
  const currentpath=window.location.pathname;
  if (currentpath.indexOf("fr")>=0){
      var language_index=1;
      var lang_ref='/fr/';
      //var cgu ='/fr/cgu/';
  }
  else if(currentpath.indexOf("es")>=0){
    language_index=2;
    lang_ref='/es/';
    //cgu ='/es/cgu/';
  }
  else{
    language_index=0;
    lang_ref='/';
    //cgu ='/gtu/';
  }
}
else{
  language_index=0;
  lang_ref='/';
  //cgu ='/gtu/';
}

var social_name=['SOCIALIZE','SUIVEZ-NOUS SUR','SÍGUENOS '];
var languages_names=['LANGUAGES','LANGUES','IDIOMAS'];
var contact_names=['CONTACT US','NOUS CONTACTER','CONTACTO'];

const Footer = () => {
  const [cgu, SetCgu]=useState();
  const { languages, social, footer, contacts } = useSiteMetadata(); /*faMapMarkerAlt and address in usesitemetadata */
  useEffect(() => {
    if (typeof window.location.pathname !== `undefined`){
      if (window.location.pathname.indexOf("fr")>=0){
          //cgu_ref.current='/fr/cgu/';
          SetCgu('/fr/cgu/')
      }
      else if(window.location.pathname.indexOf("es")>=0){
        SetCgu('/es/cgu/')
      }
      else{
        SetCgu('/gtu/')
      }
    }
  },[])
  
  return (
    <footer>
      <div className="row">
        <div className="six columns info">

          <div className="footer-logo">
            <AnchorLink to={lang_ref}>
              <img src={ footer.logo } alt="Website and company footer logo" />
            </AnchorLink>
          </div>

          <p>{ footer.text[language_index] }<a href={cgu}>{footer.terms[language_index]}</a> </p>
          
        </div>

        <div className="six columns right-cols">
          <div className="row">
            {/* <div className="columns">
              <FontAwesomeIcon icon={ faMapMarkerAlt } />
              <h3 className="address">Come Visit</h3>
              <p>
                { address.line1 }<br />
                { address.line2 }<br />
                { address.line3 }
             </p>
           </div> */}

         <div className="columns social">
           <FontAwesomeIcon icon={ faUsers } />
           <h3 className="social">{social_name[language_index]}</h3>
           <ul>
             { social.map(mySocial =>
               <li><OutboundLink href={ mySocial.url } target="_blank"  rel="noopener noreferrer">
                 { mySocial.service }
               </OutboundLink></li>
             )}
           </ul>
        </div>

        <div className="columns languages">
           <FontAwesomeIcon icon={ faGlobe } />
           <h3 className="language">{languages_names[language_index]}</h3>
           <ul>
             { languages.map(myLanguage =>
               <li><a href={ myLanguage.url }>
                 { myLanguage.service }
               </a></li>
             )}
           </ul>
        </div>

          <div className="columns last">
            <FontAwesomeIcon icon={ faEnvelope } />
             <h3 className="contact">{contact_names[language_index]}</h3>
             <ul>
               { contacts.map((contact, index) =>
                 <li><a href={ contact.url }>{ contact.text }</a></li>
               )}
             </ul>
          </div>
        </div>
      </div>

      <p className="copyright">&copy; 2021 Chastel | Adapted from woo Gatsby </p>

      <div id="go-top">
        <button title="Back to Top" onClick={() => scrollTo('#top')}>
          <FontAwesomeIcon icon={ faChevronUp } />
        </button>
      </div>
    </div>
  </footer>

)}


export default Footer
